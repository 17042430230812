import * as React from 'react';
import Seo from "../components/seo";
import { graphql, Link } from "gatsby";
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/fonts.css';

export class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.nodes;
    const recentTils = data.recentTils?.edges || [];

    return (
      <>
        <Navigation />
        <div className="min-h-screen pb-12 pt-6 font-serif bg-white">
          <div className="max-w-7xl mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
              <main className="lg:col-span-8 lg:col-start-1">
                <h2 className="text-4xl mb-12 text-gray-900">Latest Writings</h2>
                <div className="space-y-12">
                  {posts.map((post) => (
                    <article key={post.id} className="border-b border-gray-200 pb-8 last:border-b-0">
                      <div className="space-y-4">
                        <div className="text-sm text-gray-600">{post.frontmatter.date}</div>
                        <Link to={post.fields.slug} className="block group">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight">{post.frontmatter.title}</h3>
                        </Link>
                        {post.frontmatter.tags && (
                          <div className="flex flex-wrap gap-2">
                            {post.frontmatter.tags.map(tag => (
                              <Link
                                key={tag}
                                to={`/tags/${tag.toLowerCase().replace(/\s+/g, '-')}`}
                                className="text-sm text-gray-600 hover:text-brand-600"
                              >
                                #{tag}
                              </Link>
                            ))}
                          </div>
                        )}
                        <p className="text-gray-700 leading-relaxed">{post.excerpt}</p>
                        <Link 
                          to={post.fields.slug} 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline"
                        >
                          Read More →
                        </Link>
                      </div>
                    </article>
                  ))}
                </div>
              </main>

              {/* Sidebar */}
              <aside className="lg:col-span-3 lg:col-start-10">
                <div className="sticky top-8 space-y-8">
                  {/* Latest Writings section */}
                  <div className="border-l-4 border-brand-200 pl-4">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">Latest Writings</h2>
                    <div className="space-y-3">
                      {posts.slice(0, 5).map((post) => (
                        <Link 
                          key={post.id}
                          to={post.fields.slug}
                          className="block text-gray-700 hover:text-brand-600"
                        >
                          {post.frontmatter.title}
                        </Link>
                      ))}
                    </div>
                  </div>

                  {/* TILs section */}
                  {recentTils.length > 0 && (
                    <div className="border-l-4 border-brand-200 pl-4">
                      <h2 className="text-xl font-bold text-gray-900 mb-4">TILs</h2>
                      <div className="space-y-3">
                        {recentTils.map(({ node }) => (
                          <Link 
                            key={node.fields.slug}
                            to={node.fields.slug}
                            className="block text-gray-700 hover:text-brand-600"
                          >
                            {node.frontmatter.title}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </aside>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default IndexPage;

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 100
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          tags
        }
        id
        fields {
          slug
        }
        excerpt(pruneLength: 280)
      }
    }
    recentTils: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/til/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
